import { handleDefaultApiErrors } from '../api';
import { featuresApi } from './feature-api';
import { Feature } from './feature-response';

export class FeatureService {
  readonly availableFeatures = {
    valueDriverRules: 'value-driver-rules',
    similarParts: 'similar-parts',
  };

  private _features?: Feature[];

  init() {
    this.loadFeatures().then();
  }

  async loadFeatures() {
    const response = await handleDefaultApiErrors(featuresApi.getFeatures());
    if (response.status === 'OK') {
      this._features = response.features;
    }
  }

  isFeatureEnabled(feature: string): boolean {
    return !!this._features?.find(f => f.name === feature)?.enabled;
  }
}

export const featureService = new FeatureService();
